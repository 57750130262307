<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useSiteStore } from '~/stores/site.store';

const site = useSiteStore();
</script>
<template>
  <div class="bg-base">
    <p class="m-4 text-base">
      {{ $t('jpc-limits-info') }}
    </p>
  </div>
  <div class="flex p-4 bg-layer-1 w-full">
    <Button
      type="primary"
      class="w-full justify-center"
      @click="site.deactivateModal()"
    >
      {{ $t('confirm') }}
    </Button>
  </div>
</template>
